import React, {Component} from 'react';
import {connect} from 'react-redux';
import './App.scss';
import { pageSelector } from './redux/selectors';
import Account from './Account';
import Login from './Login';
import { Container, Row, Col, Image, Navbar } from 'react-bootstrap';
import {SocialIcon} from 'react-social-icons';
import FadeIn from 'react-fade-in';
import logo from './img/logo-white.png';

class App extends Component {

	render() {

		const {page} = this.props;

		let appClassName = "app h-100";
		if (page === "Account" || page === "Deposit" || page === "Withdraw") {
			appClassName += " blue";
		}

		return (
		<div className={appClassName}>
			<Navbar fixed="top">
				<FadeIn>
					<Navbar.Brand>

						<span>dCharge</span>
					</Navbar.Brand>
				</FadeIn>
			</Navbar>
			{ page === "" ? <Login /> : <Account /> }
		</div>
		);
	}
}

function mapStateToProps(state){
	return {
		page: pageSelector(state)
	}
}

export default connect(mapStateToProps)(App);
