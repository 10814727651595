import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container, Row, Col, Button, InputGroup, FormControl} from 'react-bootstrap';
import FadeIn from 'react-fade-in';
import { web3Selector, compoundAPYSelector, compoundCAPSelector, compoundCAPREACHEDSelector, compoundGOALREACHEDSelector, compoundCLOSEDSelector,compoundFINALIZEDSelector, compoundRATESelector, compoundTOKENSSelector, compoundCLOSINGTIMESelector, compoundEthInstanceSelector, accountSelector, balanceSelector, networkSelector, pageParameterSelector, supplyValueSelector, depositingSelector, depositConfirmationNumberSelector} from './redux/selectors';
import { convertWeiToEth, convertEthToWei } from './helpers';
import { BackButton } from './BackButton';
import { FadeInSpinner } from './FadeInSpinner';
import { supplyEth, finalizeCrowdsale, claimRefund, withdrawTokens } from './redux/interactions/compound';
import { setSupplyValue } from './redux/actions/deposit';
import { retrieveCEthBalance } from './redux/interactions/compound';
import ProgressBar from "@ramonak/react-progress-bar";

class Deposit extends Component {
    render() {
        const {dispatch, apy, compoundAPY, compoundCAP, compoundCAPREACHED, compoundGOALREACHED, compoundCLOSED, compoundFINALIZED, compoundRATE, compoundTOKENS, compoundCLOSINGTIME, cEthInstance, account, balance, web3, supplyValue, network,
            depositing, confirmationNumber, pageParameter} = this.props;
        const weiSupplyValue = convertEthToWei(web3, supplyValue);
        const ethSupplyValue = convertWeiToEth(web3, weiSupplyValue);
        const ethBalance = convertWeiToEth(web3, balance);
        const raisedETH = convertWeiToEth(web3, compoundAPY);
        const raisedETHCap = convertWeiToEth(web3, compoundCAP);
        const raisedCapReached = compoundCAPREACHED;
        const raisedGoalReached = compoundGOALREACHED;
        const raisedETHClosed = compoundCLOSED;
        const raisedETHRate = compoundRATE;
        const crowdsaleFinalized = compoundFINALIZED;
        const raisedETHTokens = convertWeiToEth(web3, compoundTOKENS);
        const closingTimeDateObject = new Date(compoundCLOSINGTIME * 1000);
        const raisedETHClosingTime = closingTimeDateObject.toLocaleString();

        const changeSaveValue = (e) => dispatch(setSupplyValue(e.target.value));

        const save = () => {
            switch (pageParameter) {
                case 'compound':
                    if (ethSupplyValue > (raisedETHCap - raisedETH)){
                      alert('You are trying to send more ETH than the contract allows.');
                    }
                    else {
                      supplyEth(dispatch, cEthInstance, account, weiSupplyValue, web3, network);
                    }

                    break;
                default:
                    break;
            }
        }
        const finalize = () => {
            switch (pageParameter) {
                case 'compound':
                    finalizeCrowdsale(dispatch, cEthInstance, account, web3, network);
                    break;
                default:
                    break;
            }
        }
        const refund = () => {
            switch (pageParameter) {
                case 'compound':
                    claimRefund(dispatch, cEthInstance, account, web3, network);
                    break;
                default:
                    break;
            }
        }
        const withdraw = () => {
            switch (pageParameter) {
                case 'compound':
                    withdrawTokens(dispatch, cEthInstance, account, web3, network);
                    break;
                default:
                    break;
            }
        }
        const pageContent = () => {
            return (
                <FadeIn>
                    <Container>
                        <Row>
                            <Col className="text-center">
                                {!crowdsaleFinalized ? <p><strong>Withdrawals are open after IDO has been finalized.</strong></p> : <></>}
                                <ProgressBar bgcolor={"#6a1b9a"} completed={Math.floor((raisedETH / raisedETHCap) * 100)} width="100%" height="20px" borderRadius="20px" label labelAlignment="outside"/>
                                <p><strong>{roundOff(raisedETH,2)} / {parseFloat(raisedETHCap).toFixed()} ETH Raised</strong></p>
                                <p>Your ETH Balance: {parseFloat(ethBalance).toFixed(5)} ETH</p>
                                {(!raisedETHClosed) ? <p>Closing Time: {raisedETHClosingTime}</p> : <>IDO closed!</>}
                                {raisedETHTokens > 0 ? <p>Your CHARGE Balance: {raisedETHTokens}</p> : <></>}
                                <p>1 ETH = {raisedETHRate} CHARGE</p>
                                {(!raisedETHClosed) ?
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="basic-addon1">ETH</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl onChange={changeSaveValue} type="number" min="0" max={ethBalance} step="0.001" aria-describedby="basic-addon1" />
                                </InputGroup>
                                : <></>}

                                {(!raisedETHClosed) ? <Button onClick={save}>Send ETH</Button> : <></>}
                                {(!crowdsaleFinalized && raisedETHClosed) ? <Button onClick={finalize}>Finalize IDO</Button> : <></>}
                                {(raisedGoalReached && crowdsaleFinalized && (raisedETHTokens > 0)) ? <Button onClick={withdraw}>Withdraw CHARGE</Button> : <></>}
                                {(!raisedGoalReached && crowdsaleFinalized && (raisedETHTokens > 0)) ? <Button onClick={refund}>Refund ETH</Button> : <></>}
                            </Col>
                        </Row>
                    </Container>
                </FadeIn>
            )
        }
        return (depositing ? <FadeInSpinner message={`Depositing ${supplyValue} ETH`} confirmationNumber={confirmationNumber} /> : pageContent());
    }
}

let roundOff = (num, places) => {
  const x = Math.pow(10,places);
  return Math.ceil(num * x) / x;
}

function mapStateToProps(state){
    const pageParameter = pageParameterSelector(state);
    let apy = null;
    let cap = null;
    let capReached = null;
    let goalReached = null;
    let closed = null;
    let finalized = null;
    let rate = null;
    let tokens = null;
    let closingTime = null;
    switch (pageParameter) {
        case 'compound':
            apy = compoundAPYSelector(state);
            cap = compoundCAPSelector(state);
            capReached = compoundCAPREACHEDSelector(state);
            goalReached = compoundGOALREACHEDSelector(state);
            closed = compoundCLOSEDSelector(state);
            finalized = compoundFINALIZEDSelector(state);
            rate = compoundRATESelector(state);
            tokens = compoundTOKENSSelector(state);
            closingTime = compoundCLOSINGTIMESelector(state);
            break;
        default:
            break;
    }

	return {
        pageParameter: pageParameterSelector(state),
        web3: web3Selector(state),
        account: accountSelector(state),
        balance: balanceSelector(state),
        network: networkSelector(state),
        supplyValue: supplyValueSelector(state),
        apy: apy,
        cEthInstance: compoundEthInstanceSelector(state),
        depositing: depositingSelector(state),
        compoundAPY: compoundAPYSelector(state),
        compoundCAP: compoundCAPSelector(state),
        compoundCAPREACHED: compoundCAPREACHEDSelector(state),
        compoundGOALREACHED: compoundGOALREACHEDSelector(state),
        compoundCLOSED: compoundCLOSEDSelector(state),
        compoundFINALIZED: compoundFINALIZEDSelector(state),
        compoundRATE: compoundRATESelector(state),
        compoundTOKENS: compoundTOKENSSelector(state),
        compoundCLOSINGTIME: compoundCLOSINGTIMESelector(state),
        confirmationNumber: depositConfirmationNumberSelector(state)
	}
}

export default connect(mapStateToProps)(Deposit);
