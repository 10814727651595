import Web3 from 'web3';
import Portis from "@portis/web3";
import Torus from "@toruslabs/torus-embed";
import Authereum from "authereum";
import Fortmatic from "fortmatic";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import DcentProvider from "dcent-provider";
import MewConnect from "@myetherwallet/mewconnect-web-client";
import BurnerConnectProvider from "@burner-wallet/burner-connect-provider";

// mainnet: https://mainnet.infura.io/v3/7444020cbc0a432486a393d46bd8aa2d
// rinkeby: https://rinkeby.infura.io/v3/7444020cbc0a432486a393d46bd8aa2d
// infura id: 7444020cbc0a432486a393d46bd8aa2d

export const getWeb3 = async () => {
	const providerOptions = {
		authereum: {
			package: Authereum // required
		},
		walletconnect: {
			package: WalletConnectProvider, // required
			options: {
				infuraId: "7444020cbc0a432486a393d46bd8aa2d" // required
			}
		},
		burnerconnect: {
			package: BurnerConnectProvider, // required
			options: {
				defaultNetwork: "1"
			}
		},
	  dcentwallet: {
	    package: DcentProvider, // required
	    options: {
	      rpcUrl: "https://mainnet.infura.io/v3/7444020cbc0a432486a393d46bd8aa2d" // required
	    }
	  },
		mewconnect: {
	    package: MewConnect, // required
	    options: {
	      infuraId: "7444020cbc0a432486a393d46bd8aa2d" // required
	    }
	  },
		torus: {
			package: Torus, // required
			options: {
				networkParams: {
				},
				config: {
				}
			}
		}
	};
	const web3Modal = new Web3Modal({
		network: "mainnet", // optional
		theme: "dark",
		providerOptions: providerOptions // required
	});
	const provider = await web3Modal.connect();
	return new Web3(provider);
}
