import {cEthABI, addresses} from "../../compound/cEth.js";
import { cEthLoaded, setInterestRate, setMaxCap, setCapReached, setGoalReached, setClosed, setFinalized, setRate, setTokens, setClosingTime, setUnderlyingBalance, setCEthBalance } from "../actions/compound";
import { loadBalance } from "./account";
import { depositing, depositConfirmation, finishedDepositing } from "../actions/deposit.js";
import { withdrawing, withdrawConfirmation, finishedWithdrawing } from "../actions/withdraw.js";

export const loadCompoundEther = async (dispatch, web3, account, network) => {
    const addr = addresses[network];
    const cEthInstance = new web3.eth.Contract(cEthABI, addr);
    dispatch(cEthLoaded(cEthInstance));
    calculateInterestRate(dispatch, cEthInstance, account);
    retrieveMaxCap(dispatch, cEthInstance, account);
    retrieveCapReached(dispatch, cEthInstance, account);
    retrieveGoalReached(dispatch, cEthInstance, account);
    retrieveClosed(dispatch, cEthInstance, account);
    retrieveFinalized(dispatch, cEthInstance, account);
    retrieveRate(dispatch, cEthInstance, account);
    retrieveTokens(dispatch, cEthInstance, account);
    retrieveClosingTime(dispatch, cEthInstance, account);
    return cEthInstance;
}

export const calculateInterestRate = async (dispatch, cEthInstance, account) => {
    const supplyApy = await cEthInstance.methods.weiRaised().call();
    dispatch(setInterestRate(supplyApy));
    return supplyApy;
}

export const retrieveMaxCap = async (dispatch, cEthInstance, account) => {
    const maxCap = await cEthInstance.methods.cap().call();
    dispatch(setMaxCap(maxCap));
    return maxCap;
}

export const retrieveCapReached = async (dispatch, cEthInstance, account) => {
    const capReached = await cEthInstance.methods.capReached().call();
    dispatch(setCapReached(capReached));
    return capReached;
}

export const retrieveGoalReached = async (dispatch, cEthInstance, account) => {
    const goalReached = await cEthInstance.methods.goalReached().call();
    dispatch(setGoalReached(goalReached));
    return goalReached;
}

export const retrieveClosed = async (dispatch, cEthInstance, account) => {
    const closed = await cEthInstance.methods.hasClosed().call();
    dispatch(setClosed(closed));
    return closed;
}

export const retrieveFinalized = async (dispatch, cEthInstance, account) => {
    const finalized = await cEthInstance.methods.finalized().call();
    dispatch(setFinalized(finalized));
    return finalized;
}

export const retrieveRate = async (dispatch, cEthInstance, account) => {
    const rate = await cEthInstance.methods.rate().call();
    dispatch(setRate(rate));
    return rate;
}

export const retrieveTokens = async (dispatch, cEthInstance, account) => {
    const tokens = await cEthInstance.methods.balanceOf(account).call();
    dispatch(setTokens(tokens));
    return tokens;
}

export const retrieveClosingTime = async (dispatch, cEthInstance, account) => {
    const closeTime = await cEthInstance.methods.closingTime().call();
    dispatch(setClosingTime(closeTime));
    return closeTime;
}

export const retrieveUnderlyingBalance = async (dispatch, cEthInstance, account) => {
    const underlyingBalance = await cEthInstance.methods.rate().call();
    dispatch(setUnderlyingBalance(underlyingBalance));
    retrieveCEthBalance(dispatch, cEthInstance, account);
    return underlyingBalance;
}

export const retrieveCEthBalance = async (dispatch, cEthInstance, web3, network) => {
    let cEthBalance = await cEthInstance.methods.weiRaised().call();
    cEthBalance = (cEthBalance / 1e8);
    dispatch(setCEthBalance(cEthBalance));
    return cEthBalance;
}

export const supplyEth = async (dispatch, cEthInstance, account, supplyValue, web3, network) => {
    cEthInstance.methods.buyTokens(account).send({from: account, value: supplyValue})
        .once('transactionHash', (hash) => {
            dispatch(depositing());
        })
        .on('confirmation', (number, receipt) => {
            if (number < 2){
                dispatch(depositConfirmation(number+1));
                if (number === 1) {
                    dispatch(finishedDepositing());
                    loadBalance(dispatch, web3, account, network);
                }
            }
        })
        .on('error', (error) => {
            console.log(error);
            dispatch(finishedDepositing());
            loadBalance(dispatch, web3, account, network);
            alert('Error: ' + error);
        });
}

export const finalizeCrowdsale = async (dispatch, cEthInstance, account, web3, network) => {
    cEthInstance.methods.finalize().send({from: account, value: 0})
        .once('transactionHash', (hash) => {
            dispatch(depositing());
        })
        .on('confirmation', (number, receipt) => {
            if (number < 2){
                dispatch(depositConfirmation(number+1));
                if (number === 1) {
                    dispatch(finishedDepositing());
                    loadBalance(dispatch, web3, account, network);
                }
            }
        })
        .on('error', (error) => {
            console.log(error);
            dispatch(finishedDepositing());
            loadBalance(dispatch, web3, account, network);
            alert('Error: ' + error);
        });
}

export const claimRefund = async (dispatch, cEthInstance, account, web3, network) => {
    cEthInstance.methods.claimRefund(account).send({from: account, value: 0})
        .once('transactionHash', (hash) => {
            dispatch(depositing());
        })
        .on('confirmation', (number, receipt) => {
            if (number < 2){
                dispatch(depositConfirmation(number+1));
                if (number === 1) {
                    dispatch(finishedDepositing());
                    loadBalance(dispatch, web3, account, network);
                }
            }
        })
        .on('error', (error) => {
            console.log(error);
            dispatch(finishedDepositing());
            loadBalance(dispatch, web3, account, network);
            alert('Error: ' + error);
        });
}

export const withdrawTokens = async (dispatch, cEthInstance, account, web3, network) => {
    cEthInstance.methods.withdrawTokens(account).send({from: account, value: 0})
        .once('transactionHash', (hash) => {
            dispatch(depositing());
        })
        .on('confirmation', (number, receipt) => {
            if (number < 2){
                dispatch(depositConfirmation(number+1));
                if (number === 1) {
                    dispatch(finishedDepositing());
                    loadBalance(dispatch, web3, account, network);
                }
            }
        })
        .on('error', (error) => {
            console.log(error);
            dispatch(finishedDepositing());
            loadBalance(dispatch, web3, account, network);
            alert('Error: ' + error);
        });
}

export const redeemEth = async (dispatch, cEthInstance, account, redeemAmount, web3, network) => {
    cEthInstance.methods.redeemUnderlying(redeemAmount).send({from: account})
        .once('transactionHash', (hash) => {
            dispatch(withdrawing());
        })
        .on('confirmation', (number, receipt) => {
            if(number < 2){
                dispatch(withdrawConfirmation(number+1));
                if(number === 1) {
                    dispatch(finishedWithdrawing());
                    loadBalance(dispatch, web3, account, network);
                }
            }
        })
        .on('error', (error) => {
            console.log(error);
            dispatch(finishedDepositing());
            loadBalance(dispatch, web3, account, network);
            alert('Error: ' + error);
        })
}
