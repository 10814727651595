export function cEthLoaded(cEthInstance){
    return {
        type: 'COMP_CETH_LOADED',
        cEthInstance
    }
}

export function setInterestRate(apy){
    return {
        type: 'COMP_INTEREST_RATE_SET',
        apy
    }
}

export function setMaxCap(cap){
    return {
        type: 'COMP_MAX_CAP_SET',
        cap
    }
}

export function setCapReached(capReached){
    return {
        type: 'COMP_CAPREACHED_SET',
        capReached
    }
}

export function setGoalReached(goalReached){
    return {
        type: 'COMP_GOALREACHED_SET',
        goalReached
    }
}

export function setClosed(closed){
    return {
        type: 'COMP_CLOSED_SET',
        closed
    }
}

export function setFinalized(finalized){
    return {
        type: 'COMP_FINALIZED_SET',
        finalized
    }
}

export function setRate(rate){
    return {
        type: 'COMP_RATE_SET',
        rate
    }
}

export function setTokens(tokens){
    return {
        type: 'COMP_TOKENS_SET',
        tokens
    }
}

export function setClosingTime(closingTime){
    return {
        type: 'COMP_CLOSINGTIME_SET',
        closingTime
    }
}

export function setCEthBalance(balance){
    return {
        type: 'COMP_CETH_BALANCE_SET',
        balance
    }
}

export function setUnderlyingBalance(underlyingBalance) {
    return {
        type: 'COMP_UNDERLYING_BALANCE_SET',
        underlyingBalance
    }
}
