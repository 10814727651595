import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container, Row, Col, Button, Table} from 'react-bootstrap';
import FadeIn from 'react-fade-in';
import { web3Selector, compoundAPYSelector, balanceSelector, compoundUnderlyingBalanceSelector, accountSelector} from './redux/selectors';
import { convertWeiToEth } from './helpers';
import { selectPage } from './redux/actions/display';
import { BackButton } from './BackButton';
import { topupWallet } from './redux/interactions/ramp';

//TODO:
//    - Use logos of Compound
//    - Total balance across all pools
//    - Value earned from interest in each pool (hard in Compound)
//    - Colour schemes on Deposit & Withdraw pages matching those of pools (green for Compound)
class Save extends Component {
    render() {
        const {dispatch, compoundAPY, balance, web3, compoundUnderlyingBalance, account} = this.props;

        const ethCompoundUnderlyingBalance = convertWeiToEth(web3, compoundUnderlyingBalance);

        const raisedETH = convertWeiToEth(web3, compoundAPY);

        const withdraw = (protocol) => {
            dispatch(selectPage("Withdraw", protocol));
        }

        const deposit = (protocol) => {
            dispatch(selectPage("Deposit", protocol));
        }

        const topup = () => {
            topupWallet(dispatch, account);
        }

        const actionButton = (clickAction, text, disabled=false) => {
            return (<Button disabled={disabled} size="sm" onClick={clickAction}>
                {text}
            </Button>);
        }

        return (
            <FadeIn>
                <Container>
                    <Row>
                        <Col className="text-center">
                            <BackButton dispatch={dispatch} pageName="Account" />
                            {(balance.toString() === "0") ? <p>You have no ETH in your wallet. Why not <Button size="sm" onClick={topup}>Topup</Button> ?</p> : <></>}

                                        <p><strong>{parseFloat(raisedETH).toFixed()} / 3000 ETH Raised</strong></p>
                                        <p>{actionButton(() => deposit("compound"), "Deposit", (balance.toString() === "0"))}</p>
                        </Col>
                    </Row>
                </Container>
            </FadeIn>
        )
    }
}

function mapStateToProps(state){
	return {
        web3: web3Selector(state),
        balance: balanceSelector(state),
        account: accountSelector(state),
        compoundAPY: compoundAPYSelector(state),
        compoundUnderlyingBalance: compoundUnderlyingBalanceSelector(state),
	}
}

export default connect(mapStateToProps)(Save);
